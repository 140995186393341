function fullHeight() {
    (function ($) {
        var heightBanner = $(window).outerHeight();
        $(".full-height").css("height", heightBanner);
        $(".min-fullheight").css("min-height", heightBanner);
        $('.half-height').css("height", heightBanner / 2);
        $('.nofull-height').css("height", heightBanner * 0.8);
    })(jQuery);
}

function fullsizeBanner(id) {
	var banner = jQuery(id);
	var image = banner.data('image-src');
	var video = banner.data('video-src');
	var mp4video = banner.data('mp4-src');

	banner.background({
		source: {
			poster: image,
			mp4: mp4video,
			video: video
		}
	});
}

function wall() {
	var b = $(".wall").outerWidth();
	//$(".wall-inner").css("width", 3 * b);

	$('.wall .hexagon').each(function(){
		var degrees = Math.random() * 30 - 20;
		var scale 	= (Math.random() * 50).toFixed();
		var scaled 	= (100 - scale) / 100;

		$(this).css('transform','rotate('+ degrees +'deg) scale('+ scaled +')');
	});
}
wall();

function repeat() {
	$(".hexagon").css({"right":0}).show();
    $(".hexagon").animate({right:'+=110%'},30000);
    $('.hexagon').delay(500).fadeOut(500,repeat);
}

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});


$(function() {

	var $c = $('.wall-inner'),
		$w = $(window);

	$c.carouFredSel({
		align: false,
		items: 10,
		scroll: {
			items: 1,
			duration: 4000,
			timeoutDuration: 0,
			easing: 'linear',
			pauseOnHover: 'immediate'
		}
	});

	
	$w.bind('resize.example', function() {
		var nw = $w.width();
		if (nw < 990) {
			nw = 990;
		}

		$c.width(nw * 3);
		$c.parent().width(nw);

	}).trigger('resize.example');

});


jQuery(window).resize(function () {
    fullHeight();
});

$( document ).ready(function() {
	fullHeight();
	fullsizeBanner('#homeBanner');
	fullsizeBanner('#contactBanner');
	fullsizeBanner('#block-1');
	fullsizeBanner('#block-2');
	fullsizeBanner('#block-3');
	fullsizeBanner('#block-4');
});

